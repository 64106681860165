import Typed from 'typed.js'

class HeroAnimation {

    /**
     * @private
     * @type {HTMLElement}
     */
    root = null

    /**
     * @private
     * @type {Array<string>}
     */
    headings = []

    /**
     * @private
     * @type {HTMLDivElement}
     */
    animationEl = null

    /**
     * @private
     * @type {Typed}
     */
    typedObject = null

    /**
     * 
     * @param {HTMLElement} element 
     */
    constructor(element) {
        this.root = element
        let removeEl = []
        for (let child of element.children) {
            if (child.tagName === 'H2' && child.classList.contains('animated')) {
                this.headings.push(child.innerHTML)
                removeEl.push(child)
            }
        }
        for (let element of removeEl) {
            element.remove()
        }
        this.animationEl = document.createElement('div')
        this.animationEl.setAttribute('id', 'hero-animation')
        element.append(this.animationEl)
        this.runAnimation()
    }

    runAnimation() {
        this.typedObject = new Typed(this.animationEl, {
            strings: this.headings,
            typeSpeed: 80,
            loop: this.headings.length > 1,
            cursorChar: ''
        })
    }

}

export default HeroAnimation